import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
        apiKey: "AIzaSyCHrL7BVK9iCCteS8AvAubnZfK2M4R1bOM",
        authDomain: "shop-ar-online.firebaseapp.com",
        databaseURL: "https://shop-ar-online-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "shop-ar-online",
        storageBucket: "shop-ar-online.appspot.com",
        messagingSenderId: "327554510423",
        appId: "1:327554510423:web:50ffa706c493bb846dfd12",
        measurementId: "G-RLY3SGCB6H"
    };

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export default database;